/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */

'use client';

import cn from 'classnames';
import Link from 'next/link';
import { useMediaQuery } from 'react-responsive';
import { ToggleLangAndCurrency } from '@features/toggle-lang-and-currency';

import { CONTACT, ENV } from '@shared/config';
import type { Locale } from '@shared/libs';

import { Logo } from '../logo';
import styles from './footer.module.css';

type Props = {
  locale: Locale;
};

export const Footer = ({ locale }: Props) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.footerUpper}>
          <div className={cn(styles.row, styles.media)}>
            <Logo className={styles.footerLogo} />
          </div>
          <div className={styles.linksContainer}>
            <div className={styles.links}>
              <h4 className={styles.h4}>{locale.FOOTER_MENU_1}</h4>
              <Link href="/payments/" prefetch={false}>
                {locale.FOOTER_MENU_1_1}
              </Link>
              <Link href="/contacts/" prefetch={false}>
                {locale.FOOTER_MENU_1_2}
              </Link>
              <Link href="/support/" prefetch={false}>
                {locale.FOOTER_MENU_1_4}
              </Link>
            </div>
            <div className={styles.links}>
              <h4 className={styles.h4}>
                {!isMobile ? locale.FOOTER_MENU_2 : locale.FOOTER_MENU_2_SHORT}
              </h4>
              <Link href="/category/clear/apply/" prefetch={false}>
                {locale.FOOTER_MENU_2_1}
              </Link>
              <Link href="/musicians/" prefetch={false}>
                {locale.FOOTER_MENU_2_2}
              </Link>
              <Link href="/note_orders/" prefetch={false}>
                {locale.FOOTER_MENU_2_3}
              </Link>
            </div>

            <div className={styles.links}>
              <h4 className={styles.h4}>{locale.FOOTER_MENU_4}</h4>
              <Link href="/about/" prefetch={false}>
                {locale.FOOTER_MENU_4_1}
              </Link>
              <Link href="/terms-of-sale/" prefetch={false}>
                {locale.FOOTER_MENU_4_2}
              </Link>
              <Link href="/personal-data-policy/" prefetch={false}>
                {locale.FOOTER_MENU_4_3}
              </Link>
              <Link href="/user-agreement/" prefetch={false}>
                {locale.FOOTER_MENU_4_4}
              </Link>
              <Link href="/refunds/" prefetch={false}>
                {locale.FOOTER_MENU_4_5}
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.divider} />
        <div className={cn(styles.row, styles.settingsRow)}>
          <div className={cn(styles.row, styles.copyright, styles.desctop)}>
            <p>{`© ${locale.COPYRIGHT_HOST}. ${locale.LICLIB_SUFFIX}`}</p>
            <p>{`${locale.PG_CONTACTS_TXT1}, ${locale.PG_CONTACTS_TXT2}`}</p>
          </div>

          <div className={styles.settingsContainer}>
            <div className={styles.settings}>
              <ToggleLangAndCurrency locale={locale} bottom />
              <div className={styles.contact}>
                <a href={`mailto:${locale.OG_ORGANIZATION_EMAIL}`}>
                  {locale.OG_ORGANIZATION_EMAIL}
                </a>
              </div>
            </div>

            <div className={styles.socials}>
              <a
                href={ENV.LANG === 'ru' ? CONTACT.PINTEREST_RU : CONTACT.PINTEREST}
                target="_blank"
                className={cn(styles.socialIcon, styles.pinterest)}
              />
              {ENV.LANG === 'ru' && (
                <a href={CONTACT.VK} target="_blank" className={cn(styles.socialIcon, styles.vk)} />
              )}
              {ENV.LANG === 'ru' && (
                <a
                  href="https://www.youtube.com/@note-store"
                  target="_blank"
                  className={cn(styles.socialIcon, styles.youtube)}
                />
              )}
              <a
                href={ENV.LANG === 'ru' ? CONTACT.FS_RU : CONTACT.FS}
                target="_blank"
                className={cn(styles.socialIcon, styles.facebook)}
              />
            </div>
          </div>
        </div>

        <div className={cn(styles.divider, styles.mobile)} />

        <div className={cn(styles.row, styles.copyright, styles.mobile)}>
          <p>{`© ${locale.COPYRIGHT_HOST}. ${locale.LICLIB_SUFFIX}`}</p>
          <p>{`${locale.PG_CONTACTS_TXT1}, ${locale.PG_CONTACTS_TXT2}`}</p>
        </div>
      </div>
    </footer>
  );
};
