import he from 'he';
import Link from 'next/link';
import React from 'react';
import { Locale } from '@shared/libs';
import { ButtonBack } from '@shared/ui';

import styles from './submenu-header.module.css';

type TProps = {
  locale: Locale;
  closeAllMenu: () => void;
  closeSubMenu: () => void;
  title: string;
  subURL: string;
};

export const SubmenuHeader = ({ closeAllMenu, closeSubMenu, title, locale, subURL }: TProps) => {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={styles.closeMenuIcon} onClick={closeAllMenu} />
      <ButtonBack locale={locale} onClick={closeSubMenu} />
      {subURL ? (
        <Link href={subURL} className={styles.subTitle} prefetch={false}>
          {he.decode(title)}
        </Link>
      ) : (
        <span className={styles.subTitle}>{he.decode(title)}</span>
      )}
    </>
  );
};
