import cn from 'classnames';
import React, { type ReactNode, forwardRef } from 'react';

import styles from './button-expand.module.css';

export enum ButtonExpandType {
  TRANSPARENT = 'TRANSPARENT',
  BLACK = 'BLACK',
  CLOSE = 'CLOSE',
  OPEN = 'OPEN',
}

interface ButtonExpandProps {
  children?: ReactNode;
  type: ButtonExpandType;
  onClick?: (event: React.MouseEvent) => void;
  className?: string;
}

const STYLE = {
  [ButtonExpandType.TRANSPARENT]: styles.transparent,
  [ButtonExpandType.BLACK]: styles.black,
  [ButtonExpandType.CLOSE]: styles.close,
  [ButtonExpandType.OPEN]: styles.open,
};

export const ButtonExpand: React.FC<ButtonExpandProps> = forwardRef<
  HTMLButtonElement,
  ButtonExpandProps
>(({ children, type, onClick, className }, ref) => {
  return (
    <button
      onClick={onClick}
      className={cn(className, styles.button, STYLE[type])}
      ref={ref}
      type="button"
    >
      {children}
    </button>
  );
});

ButtonExpand.displayName = 'ButtonExpand';
