'use client';

import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { useRouter } from 'next/navigation';
import { useCallback, useContext } from 'react';

import { BasketQueryKey, NotesListQueryKey } from '@shared/api';
import { AuthContext, removeToken } from '@shared/libs';
import type { Locale } from '@shared/libs';
import { typographyStyles } from '@shared/ui';

import styles from './sign-out-btn.module.css';

type TProps = {
  locale: Locale;
};

export const SignOutBtn = ({ locale }: TProps) => {
  const { replace } = useRouter();
  const { setIsAuth } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const handleClick = useCallback(async () => {
    await removeToken();
    setIsAuth(false);
    replace('/');

    setTimeout(() => {
      void queryClient.invalidateQueries({
        queryKey: [BasketQueryKey],
      });
      void queryClient.invalidateQueries({
        queryKey: [NotesListQueryKey],
      });
    }, 300);
  }, [replace, setIsAuth, queryClient]);

  return (
    <button
      type="button"
      className={cn(styles.signOutLink, typographyStyles.p1)}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick={handleClick}
    >
      {locale.PAGE_PERSONAL_SIGN_OUT}
    </button>
  );
};
