import cn from 'classnames';
import he from 'he';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { MouseEvent } from 'react';
import type { TMenu } from '@shared/types';
import { Int3 } from '@shared/ui';
import type { TMenuLevel } from '../../../../type';
import styles from './submenu-item.module.css';

type TProps = {
  data: TMenu;
  subLevel2: TMenuLevel;
  handleMouseEnter: (obj: TMenu) => void;
  className: string;
  isTablet: boolean;
  closeAllMenu?: () => void;
};

export const SubmenuItem = ({
  data,
  subLevel2,
  handleMouseEnter,
  className,
  isTablet,
  closeAllMenu,
}: TProps) => {
  const pathname = usePathname();
  const isOpen = subLevel2.data?.id === data.id;
  const hasChildren = data.children && data.children?.length > 0;
  const isActive = data.url === pathname;

  const propsRender = isTablet
    ? {
        onClick: (e: MouseEvent) => {
          if (hasChildren && !isOpen) {
            e.stopPropagation();
            e.preventDefault();
            handleMouseEnter(data);
          }

          if (isActive) {
            if (hasChildren && !isOpen) {
              return;
            }
            closeAllMenu?.();
          }
        },
      }
    : {
        onMouseEnter: () => {
          handleMouseEnter(data);
        },
      };

  return (
    <Link
      key={data.id}
      href={data.url}
      className={cn(className, styles.submenuLink, {
        [styles.hasChildren]: data.children,
        [styles.open]: isOpen,
        [styles.active]: isActive,
      })}
      {...propsRender}
      prefetch={false}
    >
      <Int3> {he.decode(data.title)}</Int3>
    </Link>
  );
};
