'use client';

import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useLoginMutation, BasketQueryKey, NotesListQueryKey } from '@shared/api';
import { AuthContext, Locale, setToken } from '@shared/libs';
import { Button, ButtonColor, Input, Int2 } from '@shared/ui';

import styles from './login-form.module.css';

interface Auth {
  login: string;
  password: string;
}

interface LoginFormProps {
  onClose?: () => void;
  locale: Locale;
}

export const LoginForm = ({ onClose, locale }: LoginFormProps) => {
  const { register, handleSubmit } = useForm<Auth>();

  const queryClient = useQueryClient();
  const { mutate, error, isSuccess, data, status } = useLoginMutation();
  const { setIsAuth } = useContext(AuthContext);
  const router = useRouter();

  useEffect(() => {
    void (async () => {
      if (isSuccess && data) {
        await setToken(data);
        setIsAuth(true);
        router.push('/personal/account/notes/');
        onClose?.();

        setTimeout(() => {
          void queryClient.invalidateQueries({
            queryKey: [BasketQueryKey],
          });
          void queryClient.invalidateQueries({
            queryKey: [NotesListQueryKey],
          });
        }, 300);
      }
    })();
  }, [isSuccess, data, status, setIsAuth, router, onClose, queryClient]);

  const submit = useCallback(
    (form: Auth) => {
      mutate(form);
    },
    [mutate],
  );

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form className={styles.form} onSubmit={handleSubmit(submit)}>
      <Input
        placeholder={locale.PAGE_PERSONAL_SIGN_IN_LOGIN_PH || 'PAGE_PERSONAL_SIGN_IN_LOGIN_PH'}
        label={locale.PAGE_PERSONAL_SIGN_IN_LOGIN || 'PAGE_PERSONAL_SIGN_IN_LOGIN'}
        formGroupClassName={styles.formGroup}
        {...register('login', { required: true })}
      />
      <Input
        placeholder={locale.PAGE_PERSONAL_SIGN_IN_PASS_PH || 'PAGE_PERSONAL_SIGN_IN_PASS_PH'}
        label={locale.PAGE_PERSONAL_SIGN_IN_PASS || 'PAGE_PERSONAL_SIGN_IN_PASS'}
        type="password"
        autoComplete="on"
        {...register('password', { required: true })}
      />

      {error?.response?.data?.error?.msg && (
        <Int2 className={styles.error}>{error?.response?.data?.error?.msg}</Int2>
      )}

      <Button type="submit" color={ButtonColor.GREEN} className={styles.btn}>
        {locale.PAGE_PERSONAL_SIGN_IN_BTN || 'PAGE_PERSONAL_SIGN_IN_BTN'}
      </Button>
    </form>
  );
};
