import cn from 'classnames';
import he from 'he';
import Link from 'next/link';
import React from 'react';
import type { TMenu } from '@shared/types';
import type { TSubmenu } from '../../type';

import styles from './menu-item.module.css';

type TProps = {
  data: TMenu;
  submenu: TSubmenu;
  isTablet: boolean;
  handleMenuItemEnter: (obj: TMenu, evt: React.MouseEvent<HTMLElement> | null) => void;
};

export const MenuItem = ({ data, submenu, handleMenuItemEnter, isTablet }: TProps) => {
  const propsRender = isTablet
    ? {
        onClick: (evt: React.MouseEvent<HTMLElement>) => {
          evt.preventDefault();
          handleMenuItemEnter(data, null);
        },
      }
    : {
        onMouseEnter: (evt: React.MouseEvent<HTMLElement>) => {
          handleMenuItemEnter(data, evt);
        },
      };

  return (
    <div
      className={cn(styles.item, {
        [styles.active]: data.id === submenu.data?.id && submenu.show,
      })}
      {...propsRender}
    >
      <Link
        href={data.url}
        prefetch={false}
        className={cn(styles.navigationLink)}
        {...(isTablet &&
          data.children && {
            onClick: (evt) => {
              evt.preventDefault();
            },
          })}
      >
        {he.decode(data.title)}
      </Link>
    </div>
  );
};
