import { Locale } from '@shared/libs';
import { P4 } from '../typography';
import styles from './button-back.module.css';

type TProps = {
  locale: Locale;
  onClick: () => void;
};

export const ButtonBack = ({ onClick, locale }: TProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={onClick} className={styles.buttonBack}>
      <P4>{locale.PAGINATION_PREV}</P4>
    </div>
  );
};
