import cn from 'classnames';
import React, { useContext, useCallback } from 'react';
import { ModalContext } from '@shared/libs';
import { SearchForm } from '../search-form';

import styles from './search-button.module.css';

type TProps = {
  title: string | undefined;
  placeholderText: string | undefined;
  isMobile: boolean;
};

export const SearchButton = ({ title = '', placeholderText, isMobile }: TProps) => {
  const openModal = useContext(ModalContext);
  const SearchFormSlot = useCallback(
    // eslint-disable-next-line react/no-unused-prop-types
    ({ onClose }: { onClose?: () => void }) => (
      <SearchForm placeholderText={placeholderText} isMobile={isMobile} callback={onClose} />
    ),
    [placeholderText, isMobile],
  );

  const handleClick = useCallback(() => {
    openModal({
      modal: {
        title,
        Content: SearchFormSlot,
      },
    });
  }, [openModal, title, SearchFormSlot]);

  return (
    <button
      onClick={handleClick}
      className={cn(styles.searchButton, styles.mobileOnly)}
      type="button"
      aria-label="search"
    >
      <span className={styles.searchButtonImg} title="search" />
    </button>
  );
};
