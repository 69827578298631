import cn from 'classnames';

import styles from './complexity.module.css';

type Props = {
  complexity: {
    level: 10 | 20 | 30;
    title: string;
  } | null;
  className?: string;
};

export const Complexity = ({ complexity, className }: Props) => {
  if (!complexity) {
    return null;
  }

  const { level, title } = complexity;

  return (
    <div
      className={cn(
        styles.status,
        {
          [styles.beginner]: level === 10,
          [styles.advanced]: level === 20,
          [styles.master]: level === 30,
        },
        className,
      )}
    >
      {title}
    </div>
  );
};
