import cn from 'classnames';
import type { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { typographyStyles } from '../typography';
import styles from './input.module.css';

type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label?: string;
  formGroupClassName?: string;
  contrast?: boolean;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, id, formGroupClassName, className, contrast, ...props }, ref) => {
    return (
      <div className={formGroupClassName}>
        {label && (
          <label
            htmlFor={id}
            className={cn(
              typographyStyles.int1,
              {
                [styles.labelContrast]: contrast,
              },
              styles.label,
            )}
          >
            {label}
          </label>
        )}
        <input
          className={cn(
            styles.input,
            {
              [styles.inputContrast]: contrast,
            },
            className,
          )}
          id={id}
          {...props}
          ref={ref}
        />
      </div>
    );
  },
);

Input.displayName = 'Input';
