import he from 'he';
import Link from 'next/link';

import { ENV } from '@shared/config';

import type { TSubmenu } from '../../../../type';
import styles from './submenu-img.module.css';

type TProps = {
  submenu: TSubmenu;
};

export const SubmenuImg = ({ submenu }: TProps) => {
  return (
    <div className={styles.submenuImg}>
      {submenu.data?.img && (
        <>
          <div
            className={styles.submenuImgBG}
            style={{
              backgroundImage: `url('${ENV.STORAGE}/${submenu.data?.img}')`,
            }}
          />
          <Link href={submenu.data.url} className={styles.submenuImgLink} prefetch={false}>
            {he.decode(submenu.data?.title || '')}
          </Link>
        </>
      )}
    </div>
  );
};
