import cn from 'classnames';
import Image from 'next/image';

import React, { forwardRef } from 'react';

import styles from './button-icon.module.css';

interface ButtonProps {
  onClick?: (event: React.MouseEvent) => void;
  label?: string;
  icon?: string;
  type?: 'submit' | 'reset' | 'button';
}

export const ButtonIcon = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ onClick, label, icon, type = 'button' }, ref) => {
    return (
      <button
        className={styles.btnIcon}
        // eslint-disable-next-line react/button-has-type
        type={type}
        onClick={onClick}
        ref={ref}
      >
        {icon && (
          <Image
            src={icon}
            width={24}
            height={24}
            alt="icon"
            className={cn({
              [styles.btnIconText]: Boolean(label),
            })}
          />
        )}
        {label}
      </button>
    );
  },
);

ButtonIcon.displayName = 'ButtonIcon';
