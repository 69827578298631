import React, { ChangeEvent, type ReactNode, forwardRef, useCallback } from 'react';

import styles from './toggle.module.css';

interface ToggleProps {
  children?: ReactNode;
  disabled?: boolean;
  checked?: boolean;
  toggleName: string;
  onClick?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(
  ({ children, disabled = false, onClick, checked, toggleName = 'toggleName' }, ref) => {
    const handleClick = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
          return;
        }

        onClick?.(event);
      },
      [onClick, disabled],
    );

    return (
      <>
        <input
          className={styles.input}
          type="checkbox"
          id={toggleName}
          checked={checked}
          onChange={handleClick}
          disabled={disabled}
          ref={ref}
        />
        <label className={styles.label} htmlFor={toggleName}>
          {children}
        </label>
      </>
    );
  },
);

Toggle.displayName = 'Toggle';

export default Toggle;
