import { Int1 } from '../../typography';

interface TitleProps {
  children?: string | number;
  className?: string;
}

export const Title = ({ className, children }: TitleProps) => (
  <Int1 className={className}>{children}</Int1>
);
