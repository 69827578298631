import cn from 'classnames';
import type { ReactNode } from 'react';

import styles from './select-blade.module.css';

type TProps = {
  title: string;
  children: ReactNode;
  className?: string;
};

export const SelectBlade = ({ children, title, className }: TProps) => {
  return (
    <div className={cn(styles.selectBlade, className)}>
      <span className={styles.title}>{title}</span>
      <div className={styles.list}>{children}</div>
    </div>
  );
};
