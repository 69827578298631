import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import React, { useState, useCallback } from 'react';

import styles from './search-form.module.css';

type TProps = {
  placeholderText?: string | undefined;
  isMobile?: boolean;
  callback?: () => void;
  q?: string;
};

export const SearchForm = ({
  placeholderText = '',
  isMobile = false,
  callback,
  q = '',
}: TProps) => {
  const router = useRouter();
  const [search, setSearch] = useState<string>(q);
  const handleClickLink = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      if (!search) {
        evt.preventDefault();
      }
    },
    [search],
  );

  return (
    <div
      className={cn(styles.searchInputWrapper, {
        [styles.isMobile]: isMobile,
      })}
    >
      <input
        className={styles.searchInput}
        placeholder={placeholderText}
        value={search}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setSearch(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && search) {
            router.push(`/search/?q=${search}`);
            callback?.();
          }
        }}
      />
      <Link
        className={styles.searchButton}
        href={`/search/?q=${search}`}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          handleClickLink(e);
          callback?.();
        }}
        prefetch={false}
      >
        <span className={styles.searchButtonImg} title="search" />
      </Link>
    </div>
  );
};
