import cn from 'classnames';

import styles from './label.module.css';

export type LabelType = 10 | 20 | 30 | '10' | '20' | '30';

const TEXT = {
  '10': 'Начинающий',
  '20': 'Продвинутый',
  '30': 'Мастер',
} as const;

export const Label = ({ type }: { type: LabelType }) => (
  <div
    className={cn(styles.status, {
      [styles.beginner]: Number(type) === 10,
      [styles.advanced]: Number(type) === 20,
      [styles.master]: Number(type) === 30,
    })}
  >
    {TEXT[type.toString()]}
  </div>
);
