import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';

import { logoIcon } from '@shared/assets';

import styles from './logo.module.css';

type TProps = {
  className?: string;
};

export const Logo = ({ className }: TProps) => {
  return (
    <Link href="/" className={styles.logo} prefetch={false}>
      <Image className={cn(styles.logoImg, className)} src={logoIcon} alt="Notes Store" priority />
    </Link>
  );
};
