import cn from 'classnames';
import { Roboto } from 'next/font/google';
import Image from 'next/image';
import { type FC } from 'react';

import { closeIcon, closeDarkIcon } from '@shared/assets';

import { H3 } from '../typography';
import styles from './model.module.css';

export const roboto = Roboto({
  weight: ['700', '500'],
  subsets: ['latin', 'cyrillic'],
});

interface ModalProps {
  Content?: FC<{ onClose?: () => void }>;
  onClose: () => void;
  title?: string;
  light?: boolean;
  fullSize?: boolean;
}

export const Modal = ({ Content, title, onClose, light, fullSize }: ModalProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.backdrop} onClick={onClose} role="presentation" />
      <div className={fullSize ? styles.modalWrapFullSize : styles.modalWrap}>
        <div
          className={cn(styles.modal, {
            [styles.modalLight]: light,
            [styles.modalFullSize]: fullSize,
          })}
        >
          <div className={styles.modalHead}>
            {title && (
              <H3
                className={cn(styles.title, roboto, {
                  [styles.titleLight]: light,
                })}
                unboundedFont={false}
              >
                {title}
              </H3>
            )}

            <button type="button" className={styles.closeBtn} onClick={onClose}>
              <Image src={light ? closeDarkIcon : closeIcon} width={32} height={32} alt="close" />
            </button>
          </div>
          <div className={fullSize ? styles.modalContentFullSize : undefined}>
            {Content && <Content onClose={onClose} />}
          </div>
        </div>
      </div>
    </div>
  );
};
