'use client';

import { useRouter } from 'next/navigation';
import { useCallback, useContext } from 'react';

import { userIcon } from '@shared/assets';
import { AuthContext, Locale, ModalContext } from '@shared/libs';
import { MenuAction } from '@shared/ui';

import { LoginForm } from './login-form';

type Props = {
  locale: Locale;
};

export const SignInBtn = ({ locale }: Props) => {
  const { push } = useRouter();
  const { isAuth } = useContext(AuthContext);
  const openModal = useContext(ModalContext);

  const handleClick = useCallback(() => {
    if (isAuth) {
      push('/personal/account/notes');
    } else {
      openModal({
        modal: {
          title: locale.PAGE_PERSONAL_SIGN_IN || 'PAGE_PERSONAL_SIGN_IN',
          // eslint-disable-next-line react/no-unstable-nested-components
          Content: (props) => <LoginForm {...props} locale={locale} />,
        },
      });
    }
  }, [locale, isAuth, openModal, push]);

  return <MenuAction icon={userIcon} onClick={handleClick} active={Boolean(isAuth)} />;
};
