/* eslint-disable react/no-unused-prop-types */
import cn from 'classnames';
import he from 'he';
import Link from 'next/link';
import React, { ReactNode } from 'react';
import { Locale } from '@shared/libs';
import type { TMenu } from '@shared/types';
import { Int3 } from '@shared/ui';
// import { SubmenuHeader } from '../submenu-header';
import styles from './submenu-level-2.module.css';

type TProps = {
  data: TMenu | null;
  open: boolean;
  isMobile: boolean;
  locale: Locale;
  closeSubMenu: () => void;
  closeAllMenu: () => void;
  ContentSlot: () => ReactNode | null;
};

export const SubmenuLevel2 = ({
  data,
  open,
  // isMobile,
  // locale,
  closeSubMenu,
  // closeAllMenu,
  // ContentSlot,
}: TProps) => {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className={styles.backdrop} onClick={closeSubMenu} />
      <div
        className={cn(styles.submenuLevel2, {
          [styles.open]: open,
          // [styles.mobile]: isMobile,
        })}
      >
        {/* {isMobile && (
          <SubmenuHeader
            closeAllMenu={closeAllMenu}
            closeSubMenu={closeSubMenu}
            title={data?.title || ''}
            subURL={data?.url || '#'}
            locale={locale}
          />
        )} */}
        {data?.children?.map(({ id, title: titleItem, url }: TMenu) => (
          <Link key={id} href={url} className={styles.submenuLink} prefetch={false}>
            <Int3>{he.decode(titleItem)}</Int3>
          </Link>
        ))}
        {/* {isMobile && <ContentSlot />} */}
      </div>
    </>
  );
};
