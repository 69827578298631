import type { TMenu } from '@shared/types';

export const DATA_MENU: TMenu[] = [
  {
    id: 1,
    level: 1,
    title: '\u041f\u0438\u0430\u043d\u0438\u043d\u043e',
    url: '/notes/',
    text: 'MENU_PIANO_TEXT',
    img: '/upload/iblock/378/7bmym2tb1juhnzj9ccv6qtts6zcgz62c/kandinsky_download_1698062983940.png',
    is_parent: true,
    children: [
      {
        id: 8,
        level: 2,
        title:
          '\u041b\u0435\u0433\u043a\u0438\u0435 \u043d\u043e\u0442\u044b \u0434\u043b\u044f \u043f\u0438\u0430\u043d\u0438\u043d\u043e',
        url: 'notes-easy-piano',
        text: '\u0422\u0435\u043a\u0441\u0442 \u0434\u043b\u044f \u043b\u0435\u0433\u043a\u0438\u0439 \u043d\u043e\u0442 \u0434\u043b\u044f \u043f\u0438\u0430\u043d\u0438\u043d\u043e',
        img: null,
      },
      {
        id: 9,
        level: 2,
        title:
          '\u041f\u0438\u0430\u043d\u0438\u043d\u043e \u0438 \u0432\u043e\u043a\u0430\u043b\u044c\u043d\u0430\u044f \u043f\u0430\u0440\u0442\u0438\u044f',
        url: 'piano-and-vocal',
        text: '\u0422\u0435\u043a\u0441\u0442 \u0434\u043b\u044f \u0440\u0430\u0437\u0434\u0435\u043b\u0430 \u043f\u0438\u0430\u043d\u0438\u043d\u043e \u0441 \u0432\u043e\u043a\u0430\u043b\u044c\u043d\u043e\u0439 \u043f\u0430\u0440\u0442\u0438\u0435\u0439.',
        img: null,
      },
      {
        id: 7,
        level: 2,
        title: '\u0414\u043b\u044f \u043d\u0430\u0447\u0438\u043d\u0430\u044e\u0449\u0438\u0445',
        url: 'piano-for-beginners',
        text: '\u041e\u043f\u0438\u0441\u0430\u043d\u0438\u0435 \u0440\u0430\u0437\u0434\u0435\u043b\u0430 \u0022\u041d\u043e\u0442\u044b \u041f\u0438\u0430\u043d\u0438\u043d\u043e \u0434\u043b\u044f \u043d\u0430\u0447\u0438\u043d\u0430\u044e\u0449\u0438\u0445\u0022',
        img: null,
      },
      {
        id: 10,
        level: 2,
        title:
          '\u041a\u043b\u0430\u0441\u0441\u0438\u0447\u0435\u0441\u043a\u0438\u0435 \u043f\u0440\u043e\u0438\u0437\u0432\u0435\u0434\u0435\u043d\u0438\u044f',
        url: 'piano-classic-notes',
        text: '\u0422\u0435\u043a\u0441\u0442 \u0434\u043b\u044f \u043a\u043b\u0430\u0441\u0441\u0438\u0447\u0435\u0441\u043a\u0438\u0445 \u043f\u0440\u043e\u0438\u0437\u0432\u0435\u0434\u0435\u043d\u0438\u0439',
        img: null,
        is_parent: true,
        children: [
          {
            id: 16,
            level: 3,
            title:
              '\u041b\u044e\u0434\u043e\u0432\u0438\u043a \u042d\u043d\u0430\u0443\u0434\u0438',
            url: '/musicians/ludovico-einaudi/',
            text: '\u0422\u0435\u043a\u0441\u0442 \u041b\u044e\u0434\u043e\u0432\u0438\u043a \u042d\u043d\u0430\u0443\u0434\u0438',
            img: null,
          },
          {
            id: 17,
            level: 3,
            title:
              '\u041b\u044e\u0434\u0432\u0438\u0433 \u0432\u0430\u043d \u0411\u0435\u0442\u0445\u043e\u0432\u0435\u043d',
            url: '/musicians/lyudvig-van-betkhoven/',
            text: '\u0422\u0435\u043a\u0441\u0442 \u0431\u0435\u0442\u0445\u043e\u0432\u0435\u043d\u0430.',
            img: null,
          },
          {
            id: 18,
            level: 3,
            title: 'Danger Dan',
            url: '/musicians/danger-dan/',
            text: '\u0422\u0435\u043a\u0441\u0442 Danger Dan',
            img: null,
          },
          {
            id: 19,
            level: 3,
            title: 'Sarah Connor',
            url: '/musicians/sarah-connor/',
            text: '\u0422\u0435\u043a\u0441\u0442 \u0441\u0430\u0440\u0430 \u043a\u043e\u043d\u043d\u043e\u0440',
            img: null,
          },
          {
            id: 20,
            level: 3,
            title: 'Billie Elish',
            url: '/musicians/billie-eilish/',
            text: '\u0442\u0435\u043a\u0441\u0442 \u0431\u0438\u043b\u043b\u0438 \u044d\u043b\u0438\u0448',
            img: null,
          },
          {
            id: 21,
            level: 3,
            title: 'Hans Zimmer',
            url: '/musicians/khans-tsimmer/',
            text: '\u0422\u0435\u043a\u0441\u0442 \u0445\u0430\u043d\u0441\u0430 \u0437\u0438\u043c\u043c\u0435\u0440\u0430',
            img: null,
          },
        ],
      },
      {
        id: 11,
        level: 2,
        title:
          '\u041c\u0443\u0437\u044b\u043a\u0430 \u0438\u0437 \u0444\u0438\u043b\u044c\u043c\u043e\u0432',
        url: 'piano-notes-from-films',
        text: '\u0422\u0435\u043a\u0441\u0442 \u0434\u043b\u044f \u043c\u0443\u0437\u044b\u043a\u0438 \u0438\u0437 \u0444\u0438\u043b\u044c\u043c\u043e\u0432.',
        img: null,
      },
      {
        id: 12,
        level: 2,
        title: '\u0414\u0436\u0430\u0437',
        url: 'piano-notes-jazz',
        text: '\u0422\u0435\u043a\u0441\u0442 \u0434\u0436\u0430\u0437',
        img: null,
      },
      {
        id: 13,
        level: 2,
        title: '\u0420\u043e\u043a',
        url: 'piano-notes-rock',
        text: '\u0422\u0435\u043a\u0441\u0442 \u0440\u043e\u043a',
        img: null,
      },
      {
        id: 14,
        level: 2,
        title: '\u041f\u043e\u043f',
        url: 'piano-notes-pop',
        text: '\u0422\u0435\u043a\u0441\u0442 \u043f\u043e\u043f',
        img: null,
      },
      {
        id: 15,
        level: 2,
        title: '\u0421\u0430\u0443\u043d\u0434\u0442\u0440\u0435\u043a\u0438',
        url: 'piano-notes-soundtrack',
        text: '\u0422\u0435\u043a\u0441\u0442 \u0434\u043b\u044f \u0441\u0430\u0443\u043d\u0434\u0442\u0440\u0435\u043a\u043e\u0432',
        img: null,
      },
    ],
  },
  {
    id: 2,
    level: 1,
    title: '\u0413\u0438\u0442\u0430\u0440\u0430',
    url: '/',
    text: 'MENU_GUITAR_TEXT',
    img: null,
  },
  {
    id: 3,
    level: 1,
    title: '\u0412\u0441\u0435 \u0438\u043d\u0441\u0442\u0440\u0443\u043c\u0435\u043d\u0442\u044b',
    url: '/',
    text: 'MENU_ALL_INSTRUMENTS_TEXT',
    img: null,
  },
  {
    id: 4,
    level: 1,
    title: '\u0416\u0430\u043d\u0440\u044b',
    url: '/',
    text: 'MENU_GENRES_TEXT',
    img: null,
  },
  {
    id: 5,
    level: 1,
    title: '\u0422\u0435\u043c\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0438\u0435',
    url: '/',
    text: '\u0422\u0435\u043c\u0430\u0442\u0438\u0447\u0435\u0441\u043a\u0438\u0435',
    img: null,
  },
];
