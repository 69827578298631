import cn from 'classnames';
import Link from 'next/link';
import React from 'react';

import { SignInBtn } from '@features/auth';
// import { PATH } from '@shared/config';
import { useGetCountBasketQuery, useGetFavoritesQuery } from '@shared/api';
import { PATHS_PAGE } from '@shared/config';
import type { Locale } from '@shared/libs';
import { stylesButton } from '@shared/ui';

import styles from './nav-buttons.module.css';

type Props = {
  desktopOnly?: boolean;
  locale: Locale;
};

export const NavButtons = ({ desktopOnly = false, locale }: Props) => {
  const { data: basket } = useGetCountBasketQuery();
  const { data: favorites } = useGetFavoritesQuery();

  return (
    <div
      className={cn(styles.navButtons, {
        [styles.desktopOnly]: desktopOnly,
      })}
    >
      <div className={styles.menuActionButtons}>
        <Link
          href="/note_orders/"
          className={cn(stylesButton.button, styles.btnMenu, styles.desktopOnly)}
          prefetch={false}
        >
          {locale.PAGE_NOTES_ORDERS_FORM_SUBMIT}
        </Link>

        <div
          className={cn(styles.menuAction, {
            [styles.menuActionActive]: basket?.data && basket.data.count > 0,
          })}
        >
          <Link href={PATHS_PAGE.CART} prefetch={false}>
            <div className={styles.shoppingCartImg} />
          </Link>
        </div>
        <div
          className={cn(styles.menuAction, {
            [styles.menuActionActive]: favorites?.data && favorites.data.length > 0,
          })}
        >
          <Link href={PATHS_PAGE.FAVORITES} prefetch={false}>
            <div className={styles.likeImg} />
          </Link>
        </div>
        <SignInBtn locale={locale} />
      </div>
    </div>
  );
};
