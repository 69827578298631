import React, { ChangeEventHandler, type ReactNode } from 'react';

import styles from './radio.module.css';

interface RadioProps {
  checked?: boolean;
  value?: string | number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  id: string;
  name: string;
  children: ReactNode;
  disabled?: boolean;
}

export const Radio: React.FC<RadioProps> = ({
  checked,
  value,
  onChange,
  id,
  name,
  children,
  disabled,
}) => {
  return (
    <div className={styles.formRadio}>
      <input
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
};
