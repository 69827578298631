import cn from 'classnames';

import { getSpacedNum } from '@shared/libs';

import { Int1 } from '../../typography';
import styles from '../tab.module.css';

interface AmountProps {
  children?: string | number;
  className?: string;
}

export const Amount = ({ className, children }: AmountProps) => (
  <Int1 className={cn(styles.amount, className)}>{children ? getSpacedNum(children) : ''}</Int1>
);
