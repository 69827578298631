type TLang = {
  text: string;
  href: string;
};

type TLangues = {
  [key: string]: TLang;
};

export const LANG_DATA: TLangues = {
  ru: {
    text: 'Русский',
    href: 'https://note-store.ru/',
  },
  en: {
    text: 'United States',
    href: 'https://note-store.com/',
  },
  de: {
    text: 'Deutsch',
    href: 'https://note-store.de/',
  },
  es: {
    text: 'El español',
    href: 'https://note-store.es/',
  },
};
