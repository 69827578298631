import { useState, useCallback } from 'react';
import { Locale } from '@shared/libs';
import { TMenu } from '@shared/types';
import { DATA_MENU } from './config';
import styles from './navigation.module.css';
import type { TSubmenu } from './type';
import { SubMenuLevel1, MenuItem } from './ui';

type TProps = {
  data?: TMenu[];
  isTablet: boolean;
  isMobile: boolean;
  locale: Locale;
  closeMenu: () => void;
};
const INITIAL_STATE: TSubmenu = {
  data: null,
  show: false,
  leftPosition: 0,
};

export const Navigation = ({ data = DATA_MENU, closeMenu, isTablet, isMobile, locale }: TProps) => {
  const [submenu, setSubmenu] = useState<TSubmenu>(INITIAL_STATE);

  const handleMenuItemEnter = useCallback(
    (obj: TMenu, evt: React.MouseEvent<HTMLElement> | null) => {
      if (obj.children) {
        setSubmenu({
          data: obj,
          show: true,
          leftPosition: evt
            ? Number(evt.currentTarget.offsetLeft + evt.currentTarget.offsetWidth)
            : 0,
        });
      } else {
        setSubmenu({
          ...submenu,
          show: false,
          leftPosition: 0,
        });
      }
    },
    [submenu],
  );

  const handleMenuLeave = useCallback(() => {
    setSubmenu({
      ...submenu,
      show: false,
    });
  }, [submenu]);

  const closeAllMenu = useCallback(() => {
    closeMenu();
    setSubmenu({
      ...submenu,
      show: false,
    });
  }, [submenu, closeMenu]);

  return (
    <div
      className={styles.navigation}
      {...(!isTablet && {
        onMouseLeave: () => {
          handleMenuLeave();
        },
      })}
    >
      {data.map((obj: TMenu) => (
        <MenuItem
          key={obj.id}
          data={obj}
          submenu={submenu}
          isTablet={isTablet}
          handleMenuItemEnter={handleMenuItemEnter}
        />
      ))}
      <SubMenuLevel1
        isTablet={isTablet}
        isMobile={isMobile}
        submenu={submenu}
        handleMenuLeave={handleMenuLeave}
        closeAllMenu={closeAllMenu}
        locale={locale}
      />
    </div>
  );
};
