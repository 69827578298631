import cn from 'classnames';
import he from 'he';
import Link from 'next/link';
import { useState, useCallback, useEffect, useRef } from 'react';
import type { Locale } from '@shared/libs';
import type { TMenu } from '@shared/types';
import type { TSubmenu, TMenuLevel } from '../../type';
import { SubmenuHeader } from '../submenu-header';
import { SubmenuLevel2 } from '../submenu-level-2';
import styles from './submenu-level-1.module.css';
import { SubmenuItem, SubmenuImg } from './ui';

type TProps = {
  submenu: TSubmenu;
  isTablet: boolean;
  isMobile: boolean;
  locale: Locale;
  closeAllMenu: () => void;
  handleMenuLeave: () => void;
};

const INITIAL_STATE: TMenuLevel = {
  data: null,
  open: false,
};

export const SubMenuLevel1 = ({
  submenu,
  isTablet,
  isMobile,
  closeAllMenu,
  handleMenuLeave,
  locale,
}: TProps) => {
  const subref = useRef<HTMLDivElement>(null);
  const [leftPosition, setLeftPosition] = useState<boolean>(false);
  const checkPosition = useCallback(() => {
    if (subref.current) {
      setLeftPosition(
        subref.current.clientWidth < submenu.leftPosition && submenu.leftPosition > 315,
      );
    } else {
      setLeftPosition(false);
    }
  }, [submenu]);
  const [subLevel2, setSubLevel2] = useState<TMenuLevel>(INITIAL_STATE);

  useEffect(() => {
    checkPosition();
  }, [submenu, subLevel2, checkPosition]);

  const handleMouseEnter = useCallback(
    (obj: TMenu) => {
      if (obj.children) {
        setSubLevel2({
          data: obj,
          open: true,
        });
        checkPosition();
      } else {
        setSubLevel2(INITIAL_STATE);
      }
    },
    [checkPosition],
  );

  const handleSubMenuLeave = useCallback(() => {
    setSubLevel2(INITIAL_STATE);
  }, []);

  const closeSubMenu = useCallback(() => {
    handleSubMenuLeave();
    handleMenuLeave();
  }, [handleMenuLeave, handleSubMenuLeave]);

  const ContentSlot = useCallback(
    () => (submenu.data?.img ? <SubmenuImg submenu={submenu} /> : null),
    [submenu],
  );

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        className={submenu.show ? styles.backdrop : undefined}
        onClick={isTablet ? closeSubMenu : undefined}
      />
      <div
        ref={subref}
        className={cn(styles.submenu, {
          [styles.open]: submenu.show,
          [styles.leftPosition]: leftPosition,
          [styles.largeMenu]: submenu.data?.id === 4,
        })}
        {...(!isTablet && {
          onMouseLeave: () => {
            handleSubMenuLeave();
          },
        })}
      >
        <div className={styles.isTabletHeader}>
          <SubmenuHeader
            closeAllMenu={closeAllMenu}
            closeSubMenu={closeSubMenu}
            locale={locale}
            title={submenu.data?.title || ''}
            subURL={submenu.data?.url || ''}
          />
        </div>

        <div className={styles.submenuContent}>
          <Link href={submenu.data?.url || '#'} className={styles.subTitle} prefetch={false}>
            {he.decode(submenu.data?.title || '#')}
          </Link>
          <div className={styles.submenuRow}>
            <div className={cn(styles.submenuColumn, styles.submenuColumn_1)}>
              <div
                className={cn(styles.submenuList, {
                  [styles.maxHeight]: !(
                    (submenu.data?.children?.find((item) => item.children) && true) ||
                    false ||
                    submenu.data?.img
                  ),
                })}
              >
                {submenu.data?.children?.map((obj: TMenu) => (
                  <SubmenuItem
                    key={obj.id}
                    data={obj}
                    subLevel2={subLevel2}
                    handleMouseEnter={handleMouseEnter}
                    className={styles.submenuLink}
                    isTablet={isTablet}
                    closeAllMenu={closeAllMenu}
                  />
                ))}
              </div>
            </div>
            {subLevel2.open && (
              <div
                className={cn(styles.submenuColumn, styles.submenuColumn_2, {
                  [styles.submenuColumn_2_large]: !submenu.data?.img,
                })}
              >
                <SubmenuLevel2
                  open={subLevel2.open}
                  data={subLevel2.data}
                  isMobile={isMobile}
                  closeSubMenu={handleSubMenuLeave}
                  closeAllMenu={closeAllMenu}
                  locale={locale}
                  ContentSlot={ContentSlot}
                />
              </div>
            )}
          </div>
        </div>
        {submenu.data?.img && <SubmenuImg submenu={submenu} />}
      </div>
    </>
  );
};
