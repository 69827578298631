import Image from 'next/image';

import styles from './menu-action.module.css';

interface MenuActionProps {
  onClick: () => void;
  icon: string;
  active?: boolean;
}

export const MenuAction = ({ onClick, icon, active }: MenuActionProps) => {
  return (
    <div className={active ? styles.active : undefined}>
      <button type="button" className={styles.menuAction} onClick={onClick}>
        <Image src={icon} className={styles.Img} alt="icon" />
      </button>
    </div>
  );
};
