import { ButtonHTMLAttributes, type DetailedHTMLProps } from 'react';

import styles from './button-figure.module.css';

export const ButtonFigure = ({
  children,
  ...props
}: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  return (
    <button className={styles.button} type="button" {...props}>
      {children}
    </button>
  );
};

export const classNameButtonFigure = styles.button;
