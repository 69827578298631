'use client';

// TODO fix jsx-a11y

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import cn from 'classnames';
import dynamic from 'next/dynamic';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { SearchForm, SearchButton } from '@features/search';
import { ToggleLangAndCurrency } from '@features/toggle-lang-and-currency';
import type { Locale } from '@shared/libs';

import { TMenu } from '@shared/types';
import { Logo } from '../logo';
import { NavButtons } from '../nav-buttons';
import { Navigation } from '../navigation';
import styles from './header.module.css';

const MenuModalDynamic = dynamic<{
  isMenuOpen: boolean;
  closeMenu: () => void;
  isTablet: boolean;
  isMobile: boolean;
  locale: Locale;
  data: TMenu[] | null | undefined;
}>(() => import('./menu-modal').then((mod) => mod.MenuModal), {
  ssr: false,
});

export const Header: FC<{
  bgColor?: 'transparent' | 'white' | undefined;
  locale: Locale;
  data: TMenu[] | null | undefined;
  q?: string;
}> = ({ bgColor = 'transparent', locale, data, q }) => {
  const isTablet = useMediaQuery({ maxWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [placeholderText, setPlaceholderText] = useState(locale.NS_SEEK_PLACEHOLDER);
  const openMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  useEffect(() => {
    if (isTablet) {
      setPlaceholderText(locale.NS_SEEK_PLACEHOLDER_SHOT);
    }
  }, [isTablet, locale]);

  useEffect(() => {
    const html = document.getElementsByTagName('html')?.[0];

    if (isMenuOpen) {
      const widthBefore = html.offsetWidth;
      html.style.overflow = 'hidden';
      html.style.marginRight = `${html.offsetWidth - widthBefore}px`;
    } else {
      html.style.overflow = 'auto';
      html.style.marginRight = `0px`;
    }
  }, [isMenuOpen]);

  return (
    <header className={bgColor === 'white' ? cn(styles.container, styles.white) : styles.container}>
      <div className={styles.header}>
        <div className={styles.row}>
          <Logo />
          <SearchForm placeholderText={placeholderText} q={q} />
          <NavButtons locale={locale} desktopOnly />
          <SearchButton
            placeholderText={placeholderText}
            title={locale.FORM_SEARCH_TITLE}
            isMobile
          />
          <div className={styles.burgerMenu} onClick={openMenu} />

          <MenuModalDynamic
            isMenuOpen={isMenuOpen}
            closeMenu={closeMenu}
            isTablet={isTablet}
            isMobile={isMobile}
            locale={locale}
            data={data}
          />
        </div>

        <div className={cn(styles.row, styles.desktopOnly)}>
          {data && (
            <Navigation
              isTablet={isTablet}
              isMobile={isMobile}
              closeMenu={closeMenu}
              data={data}
              locale={locale}
            />
          )}
          <div className={styles.settings}>
            <ToggleLangAndCurrency locale={locale} />
            <div className={styles.contact}>
              <a href={`mailto:${locale.OG_ORGANIZATION_EMAIL}`}>{locale.OG_ORGANIZATION_EMAIL}</a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
