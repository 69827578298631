import cn from 'classnames';
import React, { type ReactNode } from 'react';

import { isReactNode } from '@shared/libs';

import { Amount, Title } from './components';
import styles from './tab.module.css';

interface TabProps {
  children?: ReactNode;
  className?: string;
  dataTest?: string;
  filled?: boolean;
  onClick?: () => void;
}

export const Tab: React.FC<TabProps> & {
  Title: typeof Title;
  Amount: typeof Amount;
} = ({ children, filled, onClick }) => {
  const content: React.ReactNode[] = [];
  let title: React.ReactNode = null;
  let amount: React.ReactNode = null;
  React.Children.forEach(children, (child) => {
    if (!isReactNode(child)) {
      content.push(child);
      return;
    }

    if (child && child.type === Title) {
      title = child;
      return;
    }
    if (child && child.type === Amount) {
      amount = child;
      return;
    }

    content.push(child);
  });

  return (
    <button
      type="button"
      className={filled ? cn(styles.tab, styles.filled) : cn(styles.tab, styles.transparent)}
      onClick={onClick}
    >
      {title}
      {amount}
    </button>
  );
};

Tab.Title = Title;
Tab.Amount = Amount;
