import cn from 'classnames';
import styles from './radio-static.module.css';

interface RadioStaticProps {
  label: string | undefined;
  title: string | undefined;
  subtitle: string | undefined;
  className: string | undefined;
}

export const RadioStatic: React.FC<RadioStaticProps> = ({ label, title, subtitle, className }) => {
  return (
    <div className={cn(styles.radioStatic, className)}>
      <span className={styles.label}>{label}</span>
      <div className={styles.wrapper}>
        <span className={styles.title}>{title}</span>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
    </div>
  );
};
