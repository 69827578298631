import cn from 'classnames';
import { Loader } from '../loader';
import { Int1 } from '../typography';

import styles from './show-more.module.css';

type TProps = {
  moreResultCount: number;
  handleClick: () => void;
  isLoading: boolean;
  text: string;
  max?: number;
  className?: string;
};

export const ShowMore = ({
  moreResultCount,
  handleClick,
  text,
  isLoading,
  className,
  max = 18,
}: TProps) => {
  if (moreResultCount < 1) {
    return null;
  }

  return (
    <button
      className={cn(styles.showMore, className, {
        [styles.showMoreLoading]: isLoading,
      })}
      type="button"
      onClick={handleClick}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Int1>{`${text} ${moreResultCount > max ? max : moreResultCount}`}</Int1>
      )}
    </button>
  );
};
