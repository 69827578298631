import cn from 'classnames';
import React, { ChangeEvent, type ReactNode } from 'react';

import styles from './checkbox.module.css';

export interface CheckboxProps {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: string;
  children?: ReactNode;
  disabled?: boolean;
  checked?: boolean;
  classname?: string;
}

function getId(name: string) {
  try {
    return name?.toString().replaceAll(' ', '_') || 'check';
  } catch (e) {
    return 'check';
  }
}

export const Checkbox = ({
  name,
  onChange,
  value,
  children,
  disabled,
  checked,
  classname,
}: CheckboxProps) => {
  const id = getId(name);

  return (
    <>
      <input
        className={cn(styles.checkbox, classname)}
        id={id}
        type="checkbox"
        value={value}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
      />
      <label htmlFor={id} className={cn(styles.label, classname)}>
        <span className={styles.text}>{children}</span>
      </label>
    </>
  );
};
