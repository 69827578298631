import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import Link from 'next/link';
import { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Int4 } from '@/shared/ui';
import { ENV } from '@shared/config';
import { CurrencyContext, type Locale } from '@shared/libs';
import {
  setCurrency as setCurrencyCookies,
  getCurrency as getCurrencyCookies,
} from '../../../app/currency';
import { LANG_DATA } from './config';
import { SelectBlade } from './select-blade';

import styles from './toggle-lang-and-currency.module.css';

type TProps = {
  locale: Locale;
  bottom?: boolean;
};
type TCurrency = {
  text: string;
  symbol: string;
};

type TCurrencies = {
  [key: string]: TCurrency;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ToggleLangAndCurrency = ({ locale, bottom }: TProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const queryClient = useQueryClient();

  const CURRENCY_DATA: TCurrencies = (
    ENV.LANG === 'ru'
      ? {
          RUB: {
            text: locale.CURRENCY_RUB || 'RUB',
            symbol: '₽',
          },
          EUR: {
            text: locale.CURRENCY_EUR || 'EUR',
            symbol: '€',
          },
          GBP: {
            text: locale.CURRENCY_GBP || 'GBP',
            symbol: '£',
          },
          USD: {
            text: locale.CURRENCY_USD || 'USD',
            symbol: '$',
          },
        }
      : {
          EUR: {
            text: locale.CURRENCY_EUR || 'EUR',
            symbol: '€',
          },
          GBP: {
            text: locale.CURRENCY_GBP || 'GBP',
            symbol: '£',
          },
          USD: {
            text: locale.CURRENCY_USD || 'USD',
            symbol: '$',
          },
        }
  ) as TCurrencies;

  const { currencySelect, setCurrencySelect } = useContext(CurrencyContext);
  const handleClickItemCurrency = useCallback(
    (name: string) => {
      setCurrencyCookies(name);
      setCurrencySelect(name);
      void queryClient.invalidateQueries();
    },
    [setCurrencySelect, queryClient],
  );

  const ref = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = useCallback((e: MouseEvent): void => {
    if (ref?.current && !ref.current?.contains(e.target as Node)) {
      setIsOpen(false);
    }
  }, []);

  const loadCurrency = useCallback(() => {
    const promise = Promise.resolve(getCurrencyCookies());
    promise
      .then((res) => {
        if (res && res.value) {
          setCurrencySelect(res.value);
        } else {
          setCurrencySelect(ENV.DEFAULT_CERRENCY);
        }
      })
      .catch(() => {});
  }, [setCurrencySelect]);

  useEffect(() => {
    loadCurrency();
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside, loadCurrency]);

  return (
    <>
      <div
        className={cn(styles.backdrop, {
          [styles.active]: isOpen,
        })}
      />
      <div
        className={cn(styles.container, {
          [styles.active]: isOpen,
          [styles.bottom]: bottom,
        })}
        ref={ref}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          onClick={toggleIsOpen}
          className={cn(styles.placeholder, {
            [styles.ru]: ENV.LANG === 'ru',
            [styles.de]: ENV.LANG === 'de',
            [styles.en]: ENV.LANG === 'en',
            [styles.es]: ENV.LANG === 'es',
            [styles.active]: isOpen,
          })}
        >
          <Int4>
            {`${LANG_DATA[ENV.LANG].text}${ENV.LANG !== 'ru' ? ` / ${CURRENCY_DATA[currencySelect].symbol}` : ''}`}
          </Int4>
        </div>

        <div
          className={cn(styles.wrapper, {
            [styles.active]: isOpen,
          })}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div onClick={toggleIsOpen} className={styles.close} />
          <SelectBlade
            className={ENV.LANG !== 'ru' ? styles.mg : undefined}
            title={locale.LANG || 'LANG'}
          >
            {Object.keys(LANG_DATA).map((itemName) =>
              itemName === 'ru' && ENV.LANG !== 'ru' ? null : (
                <Link
                  key={itemName}
                  hrefLang={itemName}
                  href={LANG_DATA[itemName].href}
                  className={cn(styles.selectItem, {
                    [styles.ru]: itemName === 'ru',
                    [styles.de]: itemName === 'de',
                    [styles.en]: itemName === 'en',
                    [styles.es]: itemName === 'es',
                    [styles.active]: itemName === ENV.LANG,
                  })}
                >
                  <Int4>{LANG_DATA[itemName].text}</Int4>
                </Link>
              ),
            )}
          </SelectBlade>
          {ENV.LANG !== 'ru' && (
            <SelectBlade title={locale.CURRENCY || 'CURRENCY'}>
              {Object.keys(CURRENCY_DATA).map((itemName) => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  key={itemName}
                  onClick={() => {
                    handleClickItemCurrency(itemName);
                  }}
                  className={cn(styles.selectItem, {
                    [styles.rub]: itemName === 'RUB',
                    [styles.eur]: itemName === 'EUR',
                    [styles.gbp]: itemName === 'GBP',
                    [styles.usd]: itemName === 'USD',
                    [styles.active]: itemName === currencySelect,
                  })}
                >
                  <Int4>{CURRENCY_DATA[itemName].text}</Int4>
                </div>
              ))}
            </SelectBlade>
          )}
        </div>
      </div>
    </>
  );
};
